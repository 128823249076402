header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.header-button-add {
  transition: 100ms;
  border: 2px solid white;
  border-radius: 12px;
  background: none;
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.header-button-add:hover {
  transition: 100ms;
  border-color: rgb(255, 255, 255);
  border: 2px solid rgb(52, 146, 52);
  background-color: rgb(52, 146, 52);
}

.header-button-info {
  transition: 100ms;
  border: 2px solid white;
  border-radius: 100%;
  background: none;
  text-transform: uppercase;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.header-button-info:hover {
  transition: 100ms;
  border-color: rgb(255, 255, 255);
  border: 2px solid rgb(52, 108, 146);
  background-color: rgb(52, 108, 146);
}

header div > input + button {
  margin: 4% 20px;
}

input {
  border: none;
  border-radius: 8px;
  padding: 8px;
  min-width: 50px;
  width: 40%;
  font-size: 12pt;
  -webkit-appearance: none
}
input:focus-visible {
  border: none;
}

main {
  display: flex;
  justify-content: center;
  margin-top: 2%;
  flex-wrap: wrap;
}

.counter-item {
  display: flex;
  flex-direction: column;
  margin: 24px;
}

.counter-item-count {
  font-size: 50pt;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
